.App {
  text-align: center;
  height: 100vh;
}

h1 {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Text color white */
  color: white;
}

h2 {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Text color white */
  color: white;
}

input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

label {
	cursor: pointer;
	text-indent: -9999px;
	width: 200px;
	height: 100px;
	background: rgb(113, 172, 5);
	display: block;
	border-radius: 100px;
	position: relative;
}

label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 90px;
	height: 90px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

input:checked + label {
	background: #d25329;
}

input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

label:active:after {
	width: 130px;
}


body {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}